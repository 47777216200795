// Global Footer
// Edit mode UI

.custom-footer-extra {
  @include edit_ui_element($padding: 10px);

  &:empty {
    display: none;
  }

  display:flex;
  flex-flow: column;
  justify-content: center;
  padding: 20px 0 0 0;
}

.footer-sponsors {
  align-self: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
  max-width: $content-area-width;
  padding-bottom: 40px;

  .heroPhotoElement {
    flex-basis: calc(100% / 3);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: auto;
      height: auto;
      max-width: 90px;
      max-height: 70px;
      opacity: 1;
      transition: opacity $transition-linear;
    }
  }

  &:hover {
    .heroPhotoElement {
      img {
        opacity: .6;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.footer-info {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: $color-secondary;
}

.footer-col-logo {

  .footer-logo {
    max-width: 100px;
    margin: auto;
    padding-bottom: 10px;
  }

  .footer-social {
    .sn-social-media-list {
      display: flex;
      justify-content: center;

      .sn-social-media-icon {
        background: $color-primary;

        &:hover {
          color: $color-primary;
          background: white;
        }
      }
    }

  }

}

.footer-col-links {
  display: none;
}

.footer-disclaimer {
  text-align: center;
  max-width: $content-area-width;
  margin-left: auto;
  margin-right: auto;
  color: $footer-colophon-text-color;
  font-size: $footer-colophon-font-size;
}

footer.snFooterContainer {
  background: url(../images/custom/footer-top-border.png) center top no-repeat,
    linear-gradient(to bottom, rgba(255, 255, 255, 0) 100px, rgba(255, 255, 255, 1) 100px, rgba(255, 255, 255, 1) 100%);
  padding-top: 100px;
  margin-top: $angled-container-top-margin;
  position: relative;
  .edit_mode & {
    margin-top: 0;
  }
}

@media only screen and (min-width: 1024px) {

  .footer-sponsors {
    flex-flow: row nowrap;
    padding-left: 5%;
    padding-right: 5%;

    .heroPhotoElement {
      flex-basis: auto;

      img {
        max-width: 120px;
        max-height: 90px;
      }
    }
  }

  .footer-info {
    flex-flow: row;
    justify-content: center;

  }

  .footer-col-logo {
    flex-basis: 25%;
    justify-content: flex-start;

    .footer-logo,
    .footer-social .sn-social-media-list {
      justify-content: flex-start;
      margin: unset;
    }
  }



  .footer-col-links {
    flex-basis: 40%;
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    text-transform: uppercase;

    &>div {
      flex: 1 1 auto;
    }

    .pageElement>h3:first-child {
      font-size: 12px;
      font-weight: 700;
      line-height: calc(36/12);
    }

    p {
      margin: 0;
    }

    a {
      font-size: 12px;
      font-weight: 700;
      line-height: calc(36/12);
      text-decoration: none;
      &:hover {
        background-color: white;
        -webkit-box-shadow: 0 0 0 5px white;
        box-shadow: 0 0 0 5px white;
      }
    }
  }

}

.edit_mode {
  .footer-sponsor-logos {
    .heroPhotoElement {
      max-width: 200px;
    }
  }
}