
// Search Styles
.theme-search-box{ // Hides Search boxes while navigation is loading
  display: none;
  .has-main-nav & {
    display: flex;
    padding: 0 10px;
  }
  .has-mobile-nav &{
    display: flex;
    padding: 10px;
  }
}

.has-main-nav{
  .custom-main-nav-extra {
    display:flex;
    flex-flow: row nowrap;
    justify-content: flex-start;

    .theme-search-box {
        height:  $main-nav-height;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

      @extend %clearfix;
      ~ .theme-search-box {
        margin-left: $site-search-spacing;
      }
    }

    .theme-search-input,
    .theme-search-submit,
    .theme-search-clear {
      float: left;
      height: $site-search-height;
      line-height: $site-search-height;
      transition: background $transition-linear;
    }

    .theme-search-input,
    .theme-search-clear { font-size: $site-search-input-font-size; }

    .theme-search-input {
      font-family: "Roboto Condensed", sans-serif;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 16px;
      background: rgba(255,255,255,0.25);
      border: 0 solid #CCC;
      border-right: none;
      padding: 10px;
      color: white;
      text-align: left;

      &::-ms-clear { display: none; }
      &:focus, &:hover {
        outline: none;
        background: $site-search-input-background-focus;
        text-align: left;
      }

      &::placeholder {
        color: white;
        opacity: 1;
      }
    }

    .theme-search-submit {
      cursor: pointer;
      color: $site-search-submit-color;
      font-size: $site-search-submit-font-size;
      border: $site-search-border-width solid $site-search-submit-border-color;
      padding: 0;
      background: $site-search-submit-background;
      &:before {
        content:'\f002';
        font-family: fontawesome;
        color:white;
        font-size: 18px;
        display: inline-block;
        padding: 0 10px;
      }

      &:focus,
      &:hover { background: $site-search-submit-background-focus; }
    }

    .theme-search-clear {
      float: left;
      text-decoration: none;
      text-align: center;
      margin-left: 0 - $site-search-height - $site-search-border-width;
      margin-right: $site-search-border-width;
      width: $site-search-height;
      position:relative;
      z-index: 1;

      &:before {
        content: "✕";
        color: #9ca8af;
        font-family: FontAwesome;
        font-size: $site-search-input-font-size;
      }
    }
  }
}

@media only screen and (max-width: 1070px) {
  .has-main-nav {
    .theme-search-bar {
      padding: 0 5px 0 4px;
      .theme-search-input {
        font-size: 12px;
        padding: 0 22px 0 5px;
        max-width: 120px;
      }
    }
  }
}
