// DEFAULT MEDIA SLIDER STYLES
.mediaSlider {
    p {
        display: none;
    }
    .sn-media-slider {
        .slider {
            margin: 0;
            .slider-pagination {
                padding-left: 65px;
                padding-bottom: 65px;
                .has-mobile-nav & {
                    padding-bottom: 35px;
                    padding-left: 0;
                    text-align: center;
                }
                .paging-item {
                    height: 4px;
                    width: 30px;
                    background-color: $color-primary;
                    transition: height $transition-standard, background-color $transition-standard;
                    &:hover {
                        height: 6px;
                        background-color: $color-secondary;
                    }
                }
                .paging-item.flex-active {
                    height: 6px;
                    background-color: $color-secondary;
                }
            }
            .flex-viewport {
                .slides {
                    .slide {
                        &:hover {
                            .slide-overlay {
                                .slide-text {
                                    .slide-title {
                                        &:after {
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                            .media-wrapper {
                                &:after {
                                    opacity: 0;
                                }
                            }
                        }
                        .slide-overlay {
                            padding-left: 65px;
                            .has-mobile-nav & {
                                padding-left: 45px;
                            }
                            .slide-text {
                                padding-bottom: 30px;
                                .slide-title {
                                    display: inline-block;
                                    text-shadow: none;
                                    text-transform: uppercase;
                                    font-family: $font-primary;
                                    font-size: 36px;
                                    margin: 0;
                                    max-width: 595px;
                                    line-height: 1.25;
                                    .has-mobile-nav & {
                                        font-size: 24px;
                                        line-height: 1.25;
                                    }
                                    &:after {
                                        content: '\e5c8';
                                        font-family: 'Material Icons';
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 30px;
                                        color: $color-secondary;
                                        background: $color-primary;
                                        position: relative;
                                        bottom: -2.5px;
                                        padding: 6px 7px;
                                        right: 5px;
                                        opacity: 0;
                                        transition: opacity $transition-linear;
                                        .has-mobile-nav & {
                                            font-size: 18px;
                                            padding: 5px 0;
                                            bottom: -0.5px;
                                            right: 1px;
                                        }
                                    }
                                    .slide-title-span {
                                        box-decoration-break: clone;
                                        -webkit-box-direction: clone;
                                        display: inline;
                                        background: $color-primary;
                                        padding: 0 10px;
                                        &:empty {
                                            display: none;
                                        }
                                    }
                                }
                                .slide-description {
                                    display: none;
                                }
                            }
                        }
                        .media-wrapper {
                            &:after {
                                content: '';
                                position: absolute;
                                opacity: 0.2;
                                background-color: $color-primary;
                                width: 100%;
                                height: 100%;
                                bottom: 0;
                                display: block;
                                transition: opacity $transition-standard;
                            }
                        }
                    }
                }
            }
            .flex-direction-nav {
                a {
                    transform: none;
                    opacity: 1;
                    &:hover {
                        &:before {
                            color: $color-secondary;
                        }
                    }
                    &:before {
                        text-shadow: none;
                        font-size: 36px;
                        transition: color $transition-standard;
                    }
                }
                .flex-prev {
                    left: 20px;
                }
                .flex-next {
                    right: 20px;
                }
            }
        }
    }
}

// MOBILE HERO CONTAINER
.hero-container {
    padding-left: 43px;
    padding-right: 43px;
    padding-bottom: 15px;
    background-color: $color-secondary;
    @media screen and (max-width: $breakpoint-sm-max) {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
    }
    .column-1 {
        padding-right: 10px;
        @media screen and (max-width: $breakpoint-sm-max) {
            padding-left: 0;
            padding-right: 0;
        }
        .pageElement {
            margin-top: 10px;
        }
        .pageElement.linkElement {
            border: 2px solid black;
        }
    }
    .column-2 {
        padding-left: 10px;
        @media screen and (max-width: $breakpoint-sm-max) {
            padding-left: 20px;
            padding-right: 20px;
        }
        .pageElement {
            margin-top: 10px;
            margin-bottom: 10px;
        }
        .pageElement.linkElement {
            border: 2px solid black;
        }
    }
}