.news-container {
  background: url(../images/custom/news-bg-1.png) center top no-repeat,
    linear-gradient(to bottom, rgba(222, 222, 222, 0) 100px, rgba(222, 222, 222, 1) 100px, rgba(222, 222, 222, 1) 100%);
  padding-top: 95px;
  padding-bottom: 170px;
  margin-top: $angled-container-top-margin;
  position: relative;

  .edit_mode & {
    margin-top: 0;
  }
}


@media only screen and (min-width: 1024px) {

  .news-container {
    padding-top: 145px;
    padding-bottom: 180px;
  }


}