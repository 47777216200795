.centered-text-container {
  padding-top: 20px;
  padding-bottom: 85px;

  .column {
    padding-bottom: 50px;

    .text {

      color: $color-gray-dk;

      p {
        font-size: 14px;
        line-height: 1.5;
        color: $color-gray-dk;
      }
    }

  }

  .pageElement>h3:first-child {
    font-size: 24px;
  }

  .textBlockElement h3 {
    margin-bottom: 10px;
  }


}


@media only screen and (min-width: 1024px) {
  .centered-text-container {
    .column {
      .text {
        color: $color-gray-dk;

        p {
          font-size: 16px;
          line-height: 2.5;
        }
      }
    }

    .pageElement>h3:first-child {
      font-size: 30px;
    }

    .textBlockElement h3 {
      margin-bottom: 10px;
    }
  }
}