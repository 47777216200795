.social-container {
  background: url(../images/custom/social-bg.png) center top no-repeat,
    linear-gradient(to bottom, rgba(4, 4, 4, 0) 100px, rgba(4, 4, 4, 1) 100px, rgba(4, 4, 4, 1) 100%);
  padding-top: 85px;
  margin-top: $angled-container-top-margin;
  position: relative;

  .edit_mode & {
    margin-top: 0;
  }

  .instagram-widget iframe {
    min-width: 100%;
  }

  .user_mode & {
    .column {
      padding-bottom: 10px;
      @media only screen and (min-width: 1024px) {
        padding-bottom: 50px;
      }
      .twitterFeedElement {
        margin-bottom: 0;
        .tweet-container {
          .tweet-header {
            padding: 14px;
          }
          .tweets {
            max-height: 345px;
          }
        }
      }
      .facebook-widget,
      .instagram-widget {
        .pageElement {
          margin-bottom: 0;
        }
      }
      .twitter-widget-link,
      .facebook-widget-link,
      .instagram-widget-link {
        width: 100px;
        margin: auto;
        .linkElement {
          top: -3px;
          margin-top: 0;
          border-radius: 0;
          border-bottom-right-radius: 8px;
          border-bottom-left-radius: 8px;
          z-index: 0;
          transition: background-color $transition-linear, top $transition-linear;
          &:hover {
            background-color: $color-secondary;
            top: 0;
            h4 {
              a {
                background-color: $color-secondary;
              }
              &:before {
                background-color: #fff;
              }
            }
          }
          h4 {
            a {
              text-transform: uppercase;
              font-size: 12px;
              padding: 0;
              text-align: center;
              background: #fff;
              min-width: auto;
              justify-content: center;
              transition: background-color $transition-linear;
              &:hover {
                color: $color-primary;
              }
              &:after {
                display: none;
              }
            }
            &:before {
              content: '';
              display: block;
              width: 30px;
              height: 2px;
              background: $color-secondary;
              position: absolute;
              top: 3px;
              left: 50%;
              transform: translateX(-50%);
              transition: background-color $transition-linear;
              z-index:1;
            }
          }
        }
      }
      .pageElement {
        border-radius: 8px;
        padding: 8px;
        background: white;
        position: relative;
        z-index: 1;
        &:hover {
          &:after {
            bottom:-30px;
            padding-top:9px;
            background: $color-secondary;
          }
          &:before {
            bottom: -7px;
            background: white;
          }
        }
      }
    }
  }
}

.social-container-title {

  .user_mode & {
    flex-basis: 100%;
  }

  .pageElement>h3:first-child {
    color: white;
    font-size: 30px;
  }
}