.home {
  .has-main-nav & {
    .site-background {
      background: white;
    }

    // .yieldPageContent {
    //   padding-top: 50px;
    // }

  }

  .has-main-nav.has-sub-nav & {
    .site-background {
      background: white;
    }

    // .yieldPageContent {
    //   padding-top: 150px;
    // }

  }

  #panelTwo {
    padding-top: 0;
  }
}