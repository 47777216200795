#networkNavMenuTitle {
  width: 100%;
  border-bottom: none;

  &,
  .theme-nav-back {
    background: white;
  }

  .theme-nav-title-text {
    padding-right: $slide-nav-title-height;
  }

  .has-mobile-nav & {
    display: none;
  }
}

#networkNavMenuSites {
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  z-index: 101;
  transition: left 200ms ease-in-out;
  @include flex-box(column);

  &,
  .theme-nav-title,
  .theme-nav-forward {
    color: black;
    background: white;
  }

  .theme-nav-link, .selected .theme-nav-link {
    background: black;
  }

  .network-nav-expanded & {
    left: 0;
  }
}