.header-leagues {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  background-color: $color-secondary;
  padding: 0 50px;

  .header-leagues-inner {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    align-items: center;
    height: $header-leagues-height;
    max-width: 720px;

    &:hover {
      .heroPhotoElement {
        img {
          opacity: .6;

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

  .heroPhotoElement {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    margin: 0 15px;

    img {
      width: auto;
      height: auto;
      max-width: 75px;
      max-height: 50px;
      opacity: 1;
      transition: opacity $transition-linear;
    }
  }

  .header-sponsor-logo {
    position: relative;

    .heroPhotoElement {
      padding: 0 0 0 77px;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 38px;
      height: 50px;
      background: #ddd;
      width: 1px;
    }

    img {
      max-width: 153px;
      max-height: 35px;
    }
  }
}

.has-mobile-nav {
  .header-leagues {
    justify-content: center;
    padding: 27px 0;
    min-width: 100%;
    max-width: 100%;

    .header-leagues-inner {
      display: flex;
      flex-flow: row;
      justify-content: center;
      height: $header-leagues-mobile-height;
      min-width: 100%;
      max-width: 100%;
    }

    .heroPhotoElement {
      height: $header-leagues-mobile-height;
      margin: 0;
      margin-right: 14px;

      img {
        width: auto;
        height: auto;
        max-width: 42px;
        max-height: 27px;
        opacity: 1;
      }
    }

    .header-sponsor-logo {
      flex-basis: 130px;

      .heroPhotoElement {
        padding: 0 0 0 30px;

        img {
          max-width: 87px;
          max-height: 21px;
        }
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 14px;
        height: 30px;
        background: #ddd;
        width: 1px;
      }
    }

    &:hover {
      .heroPhotoElement {
        img {
          opacity: 1;
        }
      }
    }
  }
}