.mobile-nav-extra {
  &.sn-social-media-list {
    display: flex;
    justify-content: center;
    padding-top: 10px;

    .sn-social-media-icon {
      background: transparent;
    }
  }
}

#mobileNetworkSitesTitle {
  cursor: pointer;
  display: block;
  text-align: left;
  padding: 12px 20px;
  line-height: 1.25;
  color: #FFF;
  transition: all 0.2s linear;
  border: 1px solid white;
  border-top: 0;
  position: relative;

  &:after {
    content: '\f054';
    font-family: FontAwesome;
    font-size: 10px;
    color: white;
    border-left: 1px solid white;
    height: 100%;
    width: 44px;
    text-align: center;
    line-height: 40px;
    position: absolute;
    right: 0;
    top: 0;
  }

}