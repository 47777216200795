/* Widget - Stat Leader
---------------------------------------------------------------------------------------------------- */
.widget-leader {
  .sportTableLabel { @include sport-table-label; }

  .statTable {
    width: 100%;

    th, td {
      &:nth-child(1) { text-align: center; } // rank column
      &:nth-child(2) { text-align: left; } // name column
      &:nth-child(3) { text-align: center; } // stat column
    }

    th:nth-child(1) { width: 30px; }

    td {
      white-space: nowrap;

      &:nth-child(1) { font-weight: 700; }
    }
  }

  .leaderName {
    display: inline-block;
    width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
  }

  .leader-highlight {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 15px;
    background-color: #e6e6e6;
  }

  .stats {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0 0 0 10px;
    text-align: center;
  }

  .stats-major,
  .stats-minor {
    margin: 0;
    padding: 0;
  }

  .player_photo {
    overflow: hidden;
    height: 70px;
    border: 3px solid $link-color;
    background-color: #C7C7C7; // Same grey as default player photo

    img {
      display: block;
      width: 70px;
    }
  }
}

.NginTableWrapper {
  .NginTableScroll {
    .dataTable,
    .statTable,
    .theme-stat-table {
      thead {
        tr {
          th {
            border: none;
            text-transform: none;
            font-family: $font-secondary;
            font-size: 13px;
            height: 40px;
            padding: 0 19px 0 12px;
          }
        }
      }
      tbody {
        tr {
          border-top: 2px solid #fff;
          border-collapse: collapse;
          border-spacing: 2px;
          td {
            height: 50px;
            font-size: 13px;
            box-shadow: inset 0 2px 0 0 #fff;
            background-clip: unset !important;
            border: none;
            a {
              display: block;
            }
          }
        }
      }
    }
  }
}
