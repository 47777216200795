.custom-cta-container {
  position: relative;
  background: url(../images/custom/custom-cta-bg-3.png) center top no-repeat, white;
  padding-top: 85px;
  padding-bottom: 20px;
}

@media only screen and (min-width: 768px) {
  .custom-cta-container {
    .column {
      padding-left: 1px;
      padding-right: 1px;
    }
  }
}

.custom-cta {

  .pageElement {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .sn-call-to-action {
    padding: 0;
    height: 95px !important;

    .sn-call-to-action-overlay {
      background-color: black;
      transition: background-color $transition-linear;

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 0;
        bottom: 0;
        left: 0;
        background-color: transparent;
        transition: height $transition-linear, background-color $transition-linear;
      }
    }

    img {
      display: none;
    }

    .sn-call-to-action-title {
      background: transparent;
      margin-bottom: 0;
      padding-left: 0;
    }

    &:hover {
      .sn-call-to-action-overlay {
        background-color: rgba(black, .8);

        &:after {
          height: 4px;
          background-color: $color-secondary;
        }
      }

      .sn-call-to-action-title {
        padding-left: 5px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .custom-cta {
    .sn-call-to-action-title {
      font-size: 17px;

      &:after {
        font-size: 17px;
      }
    }
  }

}


@media only screen and (min-width: 1024px) {

  .custom-cta {
    .pageElement {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .sn-call-to-action {
      height: 125px !important;

      .sn-call-to-action-title {
        font-size: 24px;
        margin-bottom: .3em;
        padding-left: 10px;

        &:after {
          font-size: 24px;
        }
      }

      &:hover {
        .sn-call-to-action-title {
          padding-left: 15px;
        }
      }

    }
  }
}