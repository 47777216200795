/* Survey Link Element */
// 1. General Styling
// 2. Accomdidation for Small Screens and Mobile Views

// 1. General Styling
.pageElement.surveyLinkElement{
  background:transparent;
  background-image:none;
  text-align:center;
  a{
    @include global-link-style;
    @include global-link-container(7px 10px 6px);
    @include global-link-text;
    @include global-link-icon-style;
    @include global-link-icon-character;

    small{
      font-size:21px;
      font-weight: 400;
    }

  }
}
.pageElement.surveyLinkElement a:hover > span:after{
    color:#fff;
}

.hero-container {
  .pageElement.surveyLinkElement {
    border: 2px solid black;
  }
}
