// Nav Text Links
.header-nav {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  list-style-type: none;
  height: 100%;

  li {
    list-style-type: none;
    display: flex;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 700;
      padding: 0 15px;
      border-left: 1px solid $color-gray;
      border-bottom: 1px solid $color-gray;
      transition: background-color $transition-linear;
      white-space: nowrap;
      min-height: $nav-title-bar-height;
      min-width: 100px;

      &:hover {
        background-color: $color-secondary;
      }
    }
  }
  .has-mobile-nav & { display: none; }
}

// Nav Social Icons
.header-social {
  .sn-social-media-list {
    display: flex;

    .sn-social-media-icon {
      border-radius: 0;
      background: black;
      width: $nav-title-bar-height;
      height: $nav-title-bar-height;
      margin: 0;
      box-shadow: inset -1px 1px 0 0 white;
      &:before {
        line-height: $nav-title-bar-height;
        height: $nav-title-bar-height;
        width: $nav-title-bar-height;
      }
      &:hover{
        color: black;
        background: $color-secondary;
      }
    }
  }
  .has-mobile-nav & { display: none; }
}

// Nav Button
.header-button {
  .linkElement {
    a {
      font-size: 14px;
      color: white;
      background: black;
      padding: 0 30px;
      height: $nav-title-bar-height;
      min-width: unset;
      box-shadow: inset -1px 1px 0 0 white;
      &:hover {
        background-color: $color-secondary;
        color: black;
      }

      &:after {
        display: none;
      }
    }
  }
  .has-mobile-nav & { display: none; }
}


@media only screen and (max-width: 1070px) {
  .header-nav li a {
    min-width: auto;
  }
  .header-button {
    .linkElement {
      a {
        padding: 0 15px;
      }
    }
  }
}