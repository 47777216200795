.network-sites-nav {
  font-family: $font-primary;
  font-size: 14px;
  font-weight: 700;
  color: black;
  white-space: nowrap;
  position: relative;
  display: inline-block;
  height: $nav-title-bar-height;
  border-left: 1px solid $color-gray;

  &:hover {
    .network-sites-list {
      max-height: 600px;
      opacity: 1;
      top: $nav-title-bar-height;
    }

    .network-sites-title {
      background-color: $color-secondary;
    }
  }

  .network-sites-title {
    display: flex;
    align-items: center;
    background-color: white;
    border-bottom: 1px solid $color-gray;
    padding: 0 15px;
    height: $nav-title-bar-height;
    transition: background-color $transition-linear;
  }

  .network-sites-list {
    display:block;
    max-height: 1px;
    overflow-y: scroll;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.85);
    min-width: 160px;
    z-index: 1;
    top: $nav-title-bar-height - 15px;
    opacity: 0;
    transition: opacity $transition-linear, top $transition-linear, max-height $transition-linear;

    a {
      font-family: $font-secondary;
      font-size: 13px;
      font-weight: 400;
      color: white;
      padding: 12px 16px;
      text-decoration: none;
      display: block;

      &:hover {
        color: white;
        background-color: rgba(255, 255, 255, 0.15);
      }
    }
  }
  .has-mobile-nav & { display:none; }
}